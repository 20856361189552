import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFooter,{attrs:{"id":"home-footer","color":"grey darken-4","dark":"","min-height":"72"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-wrap justify-md-start justify-center justify-md-none"},[_vm._l((_vm.links),function(l,i){return [_c('RouterLink',{key:i,staticClass:"white--text pa-1 pa-md-0",attrs:{"to":l.to,"v-text":l.text}},[_vm._v(" "+_vm._s(l.text)+" ")]),(i < _vm.links.length - 1)?_c(VResponsive,{key:`divider-${l.name}`,staticClass:"mx-4 shrink hidden-sm-and-down",attrs:{"max-height":"24"}},[_c(VDivider,{attrs:{"vertical":""}})],1):_vm._e()]})],2)]),_c(VCol,{staticClass:"text-center text-md-right",attrs:{"cols":"12","md":"6"}},[_vm._v(" Copyright © 2024 FONDS POUR L'INDEPENDANCE DE LA PRESSE ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }