<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(l, i) in links">
              <RouterLink
                :key="i"
                class="white--text pa-1 pa-md-0"
                :to="l.to"
                :v-text="l.text"
              > {{ l.text }}
              </RouterLink>
              <!-- <RouterLink to="/">Go to Home</RouterLink> -->

              <v-responsive
                v-if="i < links.length - 1"
                :key="`divider-${l.name}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          Copyright &copy; 2024 FONDS POUR L'INDEPENDANCE DE LA PRESSE
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
      links: [
        { name: 'mentions', text: 'Mentions légales', to: '/mentions-legales' },
        { name: 'statuts', text: 'Les statuts', to: '/statuts' },
        { name: 'contact', text: 'Nous contacter', to: '/contact-us' },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
